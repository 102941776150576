<!-- 转运调度统计 By阿旭 -->
<template>
  <div class="main">
    <div class="row">
      <el-date-picker v-model="ldtime" size="medium" type="daterange" value-format="yyyy-MM-dd" range-separator="至"
        start-placeholder="开始日期" end-placeholder="结束日期" />
      <el-button type="primary" size="small" @click="init" style="margin-left: 20px;width:100px;">查看</el-button>
      <el-button type="primary" size="small" @click="escalateExport('btn')" style="margin-left: 20px;width:100px;">导出
      </el-button>
    </div>
    <div class="row" style="width:100%;display: flex; align-items: center;">
      <el-card style="width: 180px;text-align: center;margin-right:20px">
        <p>分配转运任务</p>
        <div style="border-top: 1px solid #dcdfe6;margin: 6px 0;"></div>
        <p style="font-weight: 600;">{{ numCount.taskNum }}人</p>
      </el-card>
      <el-card style="width: 180px;text-align: center;margin-right:20px">
        <p>转运人次</p>
        <div style="border-top: 1px solid #dcdfe6;margin: 6px 0;"></div>
        <p style="font-weight: 600;">{{ numCount.personNum }}人</p>
      </el-card>
      <el-card style="width: 180px;text-align: center;">
        <p>转运工作人员</p>
        <div style="border-top: 1px solid #dcdfe6;margin: 6px 0;"></div>
        <p style="font-weight: 600;">{{ numCount.workerNum }}人</p>
      </el-card>

    </div>
    <div class="zyrqfb">
      <div class="transportPopulationsEcharts">
        <span style="white-space:nowrap">转运人群分布</span>
        <div id="transportPopulationsEcharts"></div>
      </div>
      <div class="zyrqfb_table">
        <table>
          <tr class="biaotou">
            <td>人群分类</td>
            <td>人数</td>
          </tr>
          <tr v-for="(i, v) in classDistributed" :key="v">
            <td>{{ i.name }}</td>
            <td>{{ i.value }}</td>
          </tr>
          <tr>
            <td>合计</td>
            <td>{{ classDistributednub }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="zyrqfb">
      <div class="distributionIsolation">
        <span style="white-space:nowrap">送往隔离点分布情况</span>
        <div id="distributionIsolation"></div>
      </div>
      <div class="zyrqfb_table">
        <table>
          <tr class="biaotou">
            <td>隔离点</td>
            <td>人数</td>
          </tr>
          <tr v-for="(i, v) in isolationDistributed" :key="v">
            <td>{{ i.name }}</td>
            <td>{{ i.value }}</td>
          </tr>
          <tr>
            <td>合计</td>
            <td>{{ isolationDistributednub }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="zyrqfb">
      <div class="summary">
        <span style="white-space:nowrap">社区卫生服务中心处理任务汇总</span>
        <div id="summary"></div>
      </div>
      <div class="zyrqfb_table">
        <table>
          <tr class="biaotou">
            <td>社区卫生服务中心</td>
            <td>任务数量</td>
            <td>接送人数</td>
          </tr>

          <tr v-for="(i, v) in communityCount" :key="v">
            <td>{{ i.name }}</td>
            <td>{{ i.series1 }}</td>
            <td>{{ i.series2 }}</td>
          </tr>
          <tr>
            <td>合计</td>
            <td>{{ communityCountnub1 }}</td>
            <td>{{ communityCountnub2 }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { importFile, messageBox } from "@/utils/utils";
import * as echarts from "echarts";
import { transportScheduleCount, exportList } from '@/api/Taskmanagement/TransitSchedulingStatistics'
export default {
  data() {
    return {
      ldtime: [],
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      youb: {},
      numCount: {},
      regitsterInfo: [],
      classDistributed: [],
      classDistributednub: 0,
      communityCountnub: 0,
      isolationDistributednub: 0,
      communityCountnub1: 0,
      communityCountnub2: 0,
      isolationDistributed: [],
      communityCount: []
    };
  },

  components: {},

  computed: {},
  created() {
    this.transportScheduleCount()
  },
  mounted() {
    // this.gldb()
    // this.sqws()
  },
  methods: {
    init() {
      this.transportScheduleCount()
    },
    // 转运人群分布
    rqfl() {
      console.log(123123);
      function fontSize(res) {
        let docEl = document.documentElement,
          clientWidth =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
        if (!clientWidth) return;
        // 此处的3840 为设计稿的宽度，记得修改！
        let fontSize = clientWidth / 1920;
        return res * fontSize;
      }
      var chartDom = document.getElementById("transportPopulationsEcharts");
      var myChart = echarts.init(chartDom);
      var option;


      option = {
        tooltip: {
          trigger: "item",
        },

        series: [
          {
            name: "Access From",
            type: "pie",
            radius: "50%",
            data: this.classDistributed,
            // data: this.youb.distributed.map((item, index) => {
            //   item.label = {
            //     color: "#49C9FF",
            //   };
            //   return item;
            // }),
            emphasis: {
              itemStyle: {
                color: "#fff",
              },
            },
            normal: {
              label: {
                show: true,
                formatter: "{ b } : { c }({ d } %)",
              },
              labelLine: { show: true },
            },
          },
        ],
      };

      option && myChart.setOption(option);
      // 添加窗口大小改变监听事件，当窗口大小改变时，图表会重新绘制，自适应窗口大小
      window.addEventListener("resize", function () {
        console.log(123);

        myChart.resize();
      });
    },
    // 隔离点分布
    gldb() {
      function fontSize(res) {
        let docEl = document.documentElement,
          clientWidth =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
        if (!clientWidth) return;
        // 此处的3840 为设计稿的宽度，记得修改！
        let fontSize = clientWidth / 1920;
        return res * fontSize;
      }
      var chartDom = document.getElementById("distributionIsolation");
      var myChart = echarts.init(chartDom);
      var option;
      console.log(
        "🚀 ~ file: Largescreen.vue ~ line 743 ~ rqfl ~ this.youb.distributed",
        this.youb
      );

      option = {
        tooltip: {
          trigger: "item",
        },

        series: [
          {
            name: "Access From",
            type: "pie",
            radius: "50%",
            data: this.isolationDistributed,
            // data: this.youb.distributed.map((item, index) => {
            //   item.label = {
            //     color: "#49C9FF",
            //   };
            //   return item;
            // }),
            emphasis: {
              itemStyle: {
                color: "#fff",
              },
            },
            normal: {
              label: {
                show: true,
                formatter: "{ b } : { c }({ d } %)",
              },
              labelLine: { show: true },
            },
          },
        ],
      };

      option && myChart.setOption(option);
      // 添加窗口大小改变监听事件，当窗口大小改变时，图表会重新绘制，自适应窗口大小
      window.addEventListener("resize", function () {
        console.log(123);

        myChart.resize();
      });
    },
    // 社区卫生服务
    sqws() {
      function fontSize(res) {
        let docEl = document.documentElement,
          clientWidth =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
        if (!clientWidth) return;
        // 此处的3840 为设计稿的宽度，记得修改！
        let fontSize = clientWidth / 1920;
        return res * fontSize;
      }

      var chartDom = document.getElementById("summary");
      var myChart = echarts.init(chartDom);
      var option;
      let xdata = this.communityCount;
      console.log("🚀 ~  xdata", xdata)
      option = {
        legend: {
          data: ['任务数量', '接送人数']
        },
        tooltip: {
          trigger: "axis",
        },

        calculable: true,

        xAxis: [
          {
            type: "category",
            data: xdata.map((item) => {
              return item.name
            }),
            axisLabel: {
              color: "#6E7079",
              interval: 0, //代表显示所有x轴标签显示
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: {
              show: true,
              lineStyle: {
                color: "#E0E6F1",
              },
            },
            axisLabel: {
              color: "#6E7079",
            },
          },
        ],
        series: [
          {

            name: "任务数量",
            type: "bar",
            data: xdata.map((item) => {
              return item.series1;
            }),
            barWidth: fontSize(20),
            itemStyle: {
              color: "#5470C6",
            },
          },
          {
            name: "接送人数",
            type: "bar",
            data: xdata.map((item) => {
              return item.series2;
            }),
            barWidth: fontSize(20),
            itemStyle: {
              color: "#91CC75",
            },
          },
        ],
      };

      option && myChart.setOption(option);
      // 添加窗口大小改变监听事件，当窗口大小改变时，图表会重新绘制，自适应窗口大小
      window.addEventListener("resize", function () {
        console.log(123);

        myChart.resize();
      });
    },
    // 统计
    async transportScheduleCount() {
      if (this.ldtime && this.ldtime.length != 0) {
        this.regitsterInfo.startTime = this.ldtime[0].toString() + " 00:00:00";
        this.regitsterInfo.endTime = this.ldtime[1].toString() + " 23:59:59";
      } else {
        this.regitsterInfo.startTime = "";
        this.regitsterInfo.endTime = "";
      }
      let params = {
        startTime: this.regitsterInfo.startTime,
        endTime: this.regitsterInfo.endTime
      }
      let classDistributednub = 0
      let communityCountnub1 = 0
      let communityCountnub2 = 0
      let isolationDistributednub = 0

      try {
        let { data } = await transportScheduleCount(params)
        if (data.code == 200) {
          this.numCount = data.data.numCount
          this.classDistributed = data.data.classDistributed
          this.communityCount = data.data.communityCount
          this.isolationDistributed = data.data.isolationDistributed
          for (let i of this.classDistributed) {
            classDistributednub = +i.value + classDistributednub
          }
          for (let i of this.communityCount) {
            communityCountnub1 = +i.series1 + communityCountnub1
            communityCountnub2 = +i.series2 + communityCountnub2
          }
          for (let i of this.isolationDistributed) {
            isolationDistributednub = +i.value + isolationDistributednub
          }
          this.classDistributednub = classDistributednub
          this.isolationDistributednub = isolationDistributednub
          this.communityCountnub1 = communityCountnub1
          console.log("🚀 ~ file: TransitSchedulingStatistics.vue ~ line 383 ~ transportScheduleCount ~ communityCountnub1", communityCountnub1)
          this.communityCountnub2 = communityCountnub2
          console.log("🚀 ~ file: TransitSchedulingStatistics.vue ~ line 385 ~ transportScheduleCount ~ communityCountnub2", communityCountnub2)

          this.adsadadas()

        }
      } catch (error) {

      }

    },

    adsadadas() {
      this.rqfl()
      this.gldb()
      this.sqws()
    },
    // 导出
    escalateExport() {
      if (this.ldtime && this.ldtime.length != 0) {
        this.regitsterInfo.startTime = this.ldtime[0].toString() + " 00:00:00";
        this.regitsterInfo.endTime = this.ldtime[1].toString() + " 23:59:59";
      } else {
        this.regitsterInfo.startTime = "";
        this.regitsterInfo.endTime = "";
      }

      let params = Object.assign(
        {
          hospitalId: this.userdoctor.hospitalId,
          orgCode: this.userdoctor.orgCode,
          startTime: this.regitsterInfo.startTime,
          endTime: this.regitsterInfo.endTime
        }
      );

      messageBox(
        () => {
          this.fullscreenLoading = true;
          exportList(params).then((res) => {
            console.log(11111111111, res);
            this.fullscreenLoading = false;
            importFile(res.data, "转运调度");
          });
        },
        "warning",
        "是否确认导出 转运调度 数据？"
      );
    },

  }
}

</script>
<style lang='scss' scoped>
.main {
  width: 100%;
  height: 100%;
}

.row {
  width: 100%;
  display: flex;

  >.box-card {
    margin-right: 20px;
  }

  & {
    margin-bottom: 20px;
  }

  &:nth-of-type(n+3) /deep/ .el-card__body {
    padding: 0;
  }
}

.zyrqfb {
  width: 100%;
  display: flex;
  justify-content: start;
  margin-top: 20px;
}

#transportPopulationsEcharts {
  width: 600px;
  height: 300px;
  /* 250/192 */
}

.transportPopulationsEcharts {
  width: 600px;
  // height: 300px;
  display: flex;
  justify-content: start;
}

#distributionIsolation {
  width: 600px;
  height: 300px;

  /* 250/192 */
}

.distributionIsolation {
  width: 600px;
  display: flex;
  justify-content: start;
}

#summary {
  width: 800px;
  height: 300px;
  /* 250/192 */
}

.summary {
  width: 800px;
  height: 300px;
  display: flex;
  justify-content: start;
}


.biaotou {
  background-color: #F9F9F9;
}

.biaotou td {
  width: 160px;
  height: 40px;
}

table {
  border-collapse: collapse;
}

tr,
td {
  border: 1px solid #ccc;
  width: 160px;
  height: 40px;
  white-space: nowrap;
}

td {
  // width: 50%;
}

.zyrqfb_table {
  text-align: center;
  margin-left: 50px;
}
</style>